import React, { useState, useEffect, useContext } from 'react';
import Lottie from 'react-lottie';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from 'Context';
import VideoUploading from 'Assets/video-uploading.json';
import noPhotoAvailable from 'Assets/no-image-available.png';
import {
  DialogContent,
  LinearProgress,
  withStyles,
  CircularProgress,
  Typography,
  Box
} from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { ReactComponent as AddIcon } from 'Assets/add.svg';
import AlterImage from 'Assets/alter-image.png';
import { Button } from '../../button';
import { ContentBox, ContentBoxSection } from './ContentBox';
import VideoPreview from './VideoPreview';
import { DialogFooter } from './Material-reDesign';
import InputField from './InputField';
import { InfoModal } from '../../../Pages/private/dashboard/info-modal';
import styles from '../index.module.css';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const VideoDetails = ({
  setSelectedThumbnailIndex,
  modalType,
  uploadText,
  percentage,
  videoTitle,
  setVideoTitle,
  videoDescription,
  setVideoDescription,
  customVideoThumbnails,
  setThumbnailType,
  setDefaultVideoThumbnail,
  videoThumbnails,
  allowJpg,
  setallowJpg,
  thumbnailGenerationStatus,
  isThumbnailCreationFailed,
  videoFile,
  uploadingVideo,
  defaultVideoThumbnail,
  videoDetails,
  videoIsUploaded,
  uploadedData,
  moveToNextStep,
  commonText,
  uploadVideo,
  editFlow,
  conversionStart,
  handleSaveToDraft,
  draftApiStatus,
  filename,
  updateVisibility,
  videoVisibility,
  buttonDisabled
}) => {
  const { setAppSnackbar, pubLoader } = useContext(AppContext);
  const handleImageError = (e) => {
    e.target.src = AlterImage;
  };
  const handleThumbnailClick = (videoThumbnail, thumbnailType, idx) => {
    setSelectedThumbnailIndex && setSelectedThumbnailIndex(idx);
    setDefaultVideoThumbnail(videoThumbnail);
    setThumbnailType(thumbnailType);
  };
  return (
    <>
      <DialogContent className={styles.content}>
        {uploadingVideo && (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: VideoUploading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={16}
              style={{ flexShrink: 0 }}
              width="100%"
            />
            <br />
          </>
        )}
        <ContentBox flex>
          <div className={styles.contentBox}>
          {draftApiStatus === 'loading'
          && (
            <div className={styles.absol}>
              <p className={styles.marginX}>
                <CircularProgress />
              </p>
              <p className={styles.textx}>
              Updating details
              </p>
            </div>
              )}
            <ContentBoxSection title={uploadText?.detail || 'Details'} className={styles.detailsBoxSection}>
              <InputField
                defaultValue={videoTitle || filename}
                label={uploadText?.title || 'Title'}
                isDisabled={false}
                isRequired
                onChange={setVideoTitle}
                placeholder={uploadText?.titleVideoPlaceholder || 'Add a title to your video'}
                type="text"
              />
              <InputField
                defaultValue={videoDescription || ''}
                label={uploadText?.description || 'Description'}
                isDisabled={false}
                isOptional
                onChange={setVideoDescription}
                placeholder={uploadText?.descriptionVideoPlaceholder || 'Short lens view of your video, tell your viewers what your video is about'}
                type="textarea"
              />
            </ContentBoxSection>
            <ContentBoxSection
              title={uploadText?.thumbnail || 'Thumbnail'}
              description={uploadText?.thumbnailVideoDescription || 'Select or upload a picture that shows what’s in your video. A great thumbnail stands out and draws viewers’ attention.'}
              className={styles.thumbnailBoxSection}
            >
              <div className={styles.videoThumbnailsContainer}>
                    <Box>
                      <label
                        className={cn({ [styles.videoThumbnail]: true, [styles.thumbnailUploadButton]: true })}
                        htmlFor="video-thumbnails"
                      >
                        <AddIcon className={styles.videoThumbnailAddIcon} />
                      </label>
                    </Box>
                {customVideoThumbnails?.map((videoThumbnail, idx) => (
                  <div
                    aria-hidden="true"
                    className={cn({ [styles.videoThumbnail]: true })}
                    key={idx}
                    onClick={() => handleThumbnailClick(videoThumbnail, 'custom', idx)}
                    style={{
                      cursor: 'pointer',
                      border: `${defaultVideoThumbnail === videoThumbnail ? '4px solid #5aa5ef' : ''}`,
                    }}
                  >
                    <img
                      alt={`video thumbnail ${idx}`}
                      className={styles.videoThumbnailImage}
                      src={videoThumbnail || AlterImage}
                      onError={handleImageError}
                    />
                  </div>
                ))}
                {videoThumbnails?.length > 0 && videoThumbnails
                    ?.filter((videoThumbnail) => videoThumbnail) // Filter out undefined or null elements
                    .map((videoThumbnail, idx) => (
                      <div
                        aria-hidden="true"
                        className={cn({ [styles.videoThumbnail]: true })}
                        key={idx}
                        onClick={() => handleThumbnailClick(videoThumbnail, 'normal', idx)}
                        style={{
                          cursor: 'pointer',
                          border: `${
                            defaultVideoThumbnail
                              ? defaultVideoThumbnail === videoThumbnail || (defaultVideoThumbnail[0] && defaultVideoThumbnail[0] === videoThumbnail)
                                ? '4px solid #5aa5ef'
                                : ''
                              : ''
                          }`,
                        }}
                      >
                        <img
                          alt={`video thumbnail ${idx}`}
                          className={styles.videoThumbnailImage}
                          src={videoThumbnail || AlterImage}
                          onError={handleImageError}
                        />
                      </div>
                    ))}

                  {/* {thumbnailGenerationStatus || percentage < 98 ? (
                  <>
                    <Box>
                      <p className={styles.generateThumbnailProgress}>
                        Generating thumbnails:
                        {percentage}
                        % or Select from
                      </p>

                      <label
                        className={cn({ [styles.videoThumbnail]: true, [styles.thumbnailUploadButton]: true })}
                        htmlFor="video-thumbnails"
                      >

                        <CircularProgress className={styles.videoThumbnailAddIcon} />
                      </label>
                    </Box>

                  </>
                )
                  : (
                    <></>
                  )} */}
              </div>
            </ContentBoxSection>
          </div>
          <VideoPreview
            defaultVideoThumbnail={defaultVideoThumbnail}
            details={videoDetails}
            isThumbnailCreationFailed={isThumbnailCreationFailed}
            type="details"
            modalType={modalType}
            editFlow={editFlow}
            percentage={percentage}
          />
        </ContentBox>
      </DialogContent>
      <DialogFooter twoEnd={conversionStart}>
        <div className={styles.buttonOnEnd}>
          {/* {conversionStart ? (
            <div className={styles.leftContainer}>
              <div className={styles.iconContainer}>
                <CloudUploadOutlined className={styles.videoUploadIcon} fontSize="large" />
              </div>
              <div className={styles.progressbar}>
                <BorderLinearProgress />
                <div className={styles.processingText}>
                  Processing in background...
                </div>
              </div>
            </div>
          ) : null} */}
          {editFlow && <EditSaveToDraftButton videoTitle={videoTitle} uploadedData={uploadedData} defaultVideoThumbnail={defaultVideoThumbnail} videoVisibility={videoVisibility} handleSaveToDraft={handleSaveToDraft} draftApiStatus={draftApiStatus} updateVisibility={updateVisibility} pubLoader={pubLoader} buttonDisabled={buttonDisabled} />}
          {!editFlow && <SaveToDraftButton videoTitle={videoTitle} uploadedData={uploadedData} defaultVideoThumbnail={defaultVideoThumbnail} videoVisibility={videoVisibility} handleSaveToDraft={handleSaveToDraft} draftApiStatus={draftApiStatus} buttonDisabled={buttonDisabled} />}
          {(draftApiStatus !== 'loading')
          && (
          <Button
            className={styles.navButton}
            isBlue
            // isDisabled={buttonDisabled}
            isOutlined
            onClick={moveToNextStep}
          >
            Next
          </Button>
          )}
        </div>
      </DialogFooter>
    </>
  );
};

export default VideoDetails;

export function SaveToDraftButton({ videoTitle, uploadedData, defaultVideoThumbnail, videoVisibility, handleSaveToDraft, draftApiStatus, buttonDisabled }) {
  return (
    draftApiStatus !== 'loading' && (
      <Button
        className={styles.navButton}
        isBlue
        isOutlined
        isDisabled={buttonDisabled}
        onClick={handleSaveToDraft}
      >
        {(uploadedData?.category_id && uploadedData?.category_id !== 'delete' && defaultVideoThumbnail && (defaultVideoThumbnail !== noPhotoAvailable) && videoVisibility) ? 'Publish' : 'Save to Draft'}
      </Button>
    )
  );
}
export function EditSaveToDraftButton({ pubLoader, updateVisibility, uploadedData, defaultVideoThumbnail, videoVisibility, handleSaveToDraft, draftApiStatus, buttonDisabled }) {
  return (
    draftApiStatus !== 'loading' && (
      <Button
        className={styles.navButton}
        isBlue
        isOutlined
        isDisabled={pubLoader}
        onClick={updateVisibility}
      >
        {pubLoader === true
        ? <CircularProgress />
        : (uploadedData?.category_id && uploadedData?.category_id !== 'delete' && defaultVideoThumbnail && (defaultVideoThumbnail !== noPhotoAvailable) && videoVisibility) ? 'Publish' : 'Save to Draft'}
      </Button>
    )
  );
}
